import { Link } from "gatsby";
import React from "react";

const Mobile: React.FC = () => {
    return (<>
		<nav className="navbar navbar-primary bg-primary d-block d-md-none">
			<div className="container-fluid">
				<div className="d-flex justify-content-end w-100">
					<button className="navbar-toggler bg-white" type="button" data-bs-toggle="collapse" data-bs-target="#navbarToggleExternalContent" aria-controls="navbarToggleExternalContent" aria-expanded="false" aria-label="Toggle navigation">
						<span className="navbar-toggler-icon"></span>
					</button>
				</div>
			</div>
		</nav>
		<div id="navbarToggleExternalContent" className="collapse">
			<div className="mobile-menu-area">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							{/* <div className="mobile-menu"> */}
								{/* <nav id="dropdown"> */}
								<nav>
									<ul className="nav flex-column">
										<li className="nav-item dropdown">
											<Link className="nav-link dropdown-toggle" data-bs-toggle="dropdown"
											      to="/about" role="button" aria-expanded="false">Tentang Kami</Link>
											<ul className="dropdown-menu">
												<li><Link className="dropdown-item" to="/about#latarbelakang">Latar belakang</Link></li>
												<li><Link className="dropdown-item" to="/about#trustees">Trustees</Link></li>
												<li><Link className="dropdown-item" to="/about#leadership">Leadership</Link></li>
												<li><Link className="dropdown-item" to="/about#team">Team</Link></li>
												<li><Link className="dropdown-item" to="/about#visi">Visi</Link></li>
												<li><Link className="dropdown-item" to="/about#misi">Misi</Link></li>
												<li><Link className="dropdown-item" to="/about#whymarvinfoundation">Why Marvin Foundation</Link></li>
											</ul>
										</li>
										<li className="nav-item dropdown">
											<Link className="nav-link dropdown-toggle" data-bs-toggle="dropdown"
												  to="/program">Program</Link>
											<ul className="dropdown-menu">
												<li><Link className="dropdown-item" to="/program#webinar">Future Leader Webinar</Link></li>
												<li><Link className="dropdown-item" to="/program#scholarship">Future Leader Scholarship</Link></li>
												<li><Link className="dropdown-item" to="/program#bootcamp">Future Leader Bootcamp</Link></li>
												<li><Link className="dropdown-item" to="/program#basecamp">Future Leader Basecamp</Link></li>
												<li><Link className="dropdown-item" to="/program#marvincare">Marvin Care</Link></li>
												<li><Link className="dropdown-item" to="/program#youngdigipreneur">Young Digipreneur</Link></li>
											</ul>
										</li>
										<li className="nav-item dropdown">
											<Link className="nav-link dropdown-toggle" data-bs-toggle="dropdown"
												  to="/publication">Publikasi</Link>
											<ul className="dropdown-menu">
												<li><Link className="dropdown-item" to="/publication#compro">Company Profile</Link></li>
												<li><Link className="dropdown-item" to="/publication#news">Berita Terbaru</Link></li>
												<li><Link className="dropdown-item" to="/publication#feed">Instagram Feed</Link></li>
												<li><Link className="dropdown-item" to="/publication#article">Artikel</Link></li>
											</ul>
										</li>
										<li className="nav-item dropdown">
											<a className="nav-link dropdown-toggle" data-bs-toggle="dropdown"
											   href="javascript:" role="button" aria-expanded="false">Get Involved</a>
											<ul className="dropdown-menu">
												<li><Link className="dropdown-item" to="/donate">Donasi</Link></li>
												<li><Link className="dropdown-item" to="/join-us">Lowongan</Link></li>
											</ul>
										</li>
										<li className="nav-item"><Link className="nav-link" to="/contact-us">Kontak</Link></li>
										{/* <li className="nav-item dropdown">
											<a className="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false">Pages 1</a>
											<ul className="dropdown-menu">
												<li><Link className="dropdown-item" to="/shop/cart">Cart</Link></li>
												<li><Link className="dropdown-item" to="/shop/left-sidebar">Shop left sidebar</Link></li>
												<li><Link className="dropdown-item" to="/shop/right-sidebar">Shop right sidebar</Link></li>
												<li><Link className="dropdown-item" to="/shop/list">Shop list</Link></li>
												<li><Link className="dropdown-item" to="/shop/full-width">Shop fullwidth</Link></li>
											</ul>
										</li>
										<li className="nav-item dropdown">
											<a className="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false">Pages 2</a>
											<ul className="dropdown-menu">
												<li><Link className="dropdown-item" to="/blog-details">Blog details</Link></li>
												<li><Link className="dropdown-item" to="/shop/checkout">Checkout</Link></li>
												<li><Link className="dropdown-item" to="/gallery">Gallery</Link></li>
												<li><Link className="dropdown-item" to="/my-account">My account</Link></li>
											</ul>
										</li>
										<li className="nav-item dropdown">
											<a className="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false">Pages 3</a>
											<ul className="dropdown-menu">
												<li><Link className="dropdown-item" to="/causes">Causes</Link></li>
												<li><Link className="dropdown-item" to="/portfolio">Portfolio</Link></li>
												<li><Link className="dropdown-item" to="/services">Services</Link></li>
												<li><Link className="dropdown-item" to="/single-product">Single product</Link></li>
												<li><Link className="dropdown-item" to="/team-member">Team member</Link></li>
												<li><Link className="dropdown-item" to="/shop/wishlist">Wishlist</Link></li>
												<li><Link className="dropdown-item" to="/404">404</Link></li>
											</ul>
										</li> */}
									</ul>
								</nav>
							{/* </div>					 */}
						</div>
					</div>
				</div>
			</div>
		</div>
    </>);
}
 
export default Mobile;